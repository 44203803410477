<template>
  <v-sheet
    v-editable="blok"
    v-bind="sectionProps"
  >
    <v-container
      class="space-y-8 md:space-y-12"
      :class="sectionClass"
    >
      <v-img
        v-if="blok?.proTheme"
        :src="ProLogo"
        width="200"
        class="mx-auto"
      />

      <div class="space-y-4">
        <u-text
          class="text-36 sm:text-54 font-galano text-center"
          :text="blok.title"
        />
        <u-text
          v-if="blok.subtitle"
          class="text-18 sm:text-22 text-center max-sm:[&>*]:inline"
        >
          <u-content
            :document="blok.subtitle"
            :resolvers="resolvers"
          />
        </u-text>
      </div>

      <lazy-home-section-content
        v-if="blok.content"
        :content="blok.content"
      />

      <template v-if="blok.trustpilot">
        <lazy-trustpilot-widget
          data-schema-type="Organization"
          data-stars="5"
          data-style-height="225px"
          data-template-id="54ad5defc6454f065c28af8b"
        />
      </template>

      <v-row
        v-if="blok.links?.length"
        v-resize="updateSize"
      >
        <template
          v-for="(link, index) in blok.links"
          :key="index"
        >
          <v-col
            v-show="shouldShow(index + 1)"
            cols="24"
            :md="index < 2 ? 12 : 8 "
          >
            <v-card
              v-bind="getLink(link.full_slug).props"
              variant="flat"
              color="primary-teal"
              height="237"
              class="rounded-none"
              @click="trackEvent({ event: 'gaEvent', eventAction: 'click', eventCategory: 'homepage', eventLabel: `homepage_content_${slug(link.name, '_')}_block` })"
            >
              <img-default
                :src="link.content.previewImage.filename"
                :alt="link.content.previewImage?.alt"
                cover
                sizes="400px sm:445px md:600px lg:660px xl:660px xxl:660px"
                height="100%"
              >
                <v-chip
                  variant="flat"
                  :text="link.name"
                  class="absolute rounded-none font-galano left-3 bottom-3 text-18 h-10 px-4"
                  color="surface-elevated-1"
                />
              </img-default>
            </v-card>
          </v-col>
        </template>

        <template v-if="smAndDown && visibleBlocks < blok.links.length">
          <v-col
            cols="24"
            class="text-center pt-4"
          >
            <u-btn
              size="large"
              variant="secondary"
              text="Load more"
              @click="showMore"
            />
          </v-col>
        </template>
      </v-row>

      <div
        v-if="blok.cta && blok.cta.length"
        class="text-center sm:pt-2"
      >
        <storyblok-component
          v-for="(cta, index) in blok.cta"
          v-bind="getLink(cta.url).props"
          :key="index"
          :blok="cta"
          :text="cta.text"
          :variant="cta.variant"
          :size="cta.size"
          :inverted="cta.inverted"
          :pro="cta.pro"
          @click="trackEvent({ event: 'gaEvent', eventAction: 'click', eventCategory: 'homepage', eventLabel: cta.url.label })"
        />
      </div>
    </v-container>
  </v-sheet>
</template>

<script setup lang="ts">
import slug from 'slug'
import { BlockTypes } from '@storyblok/richtext'
import ProLogo from '~/assets/images/logo-pro-full-white.svg'
import type { HomeSectionStoryblok } from '#shared/types/storyblok-component-types'
import { paragraph } from '#storyblok/utils/resolvers/block-types'

const { blok } = defineProps<{
  blok: HomeSectionStoryblok
}>()

const { smAndDown, mdAndUp } = useDisplay()
const { getLink } = useLinkType()
const { trackEvent } = useGtm()

const sectionClass = computed(() => [
  blok.trustpilot && mdAndUp.value ? 'pt-12 pb-30' : 'py-12 md:py-30',
])

const sectionProps = computed(() => ({
  color: blok.background as string ?? 'white',
  theme: blok.proTheme ? 'pro' : 'consumer',
}))

const resolvers = {
  [BlockTypes.PARAGRAPH]: paragraph({
    class: false,
  }),
}

const visibleBlocks = ref(3)

const updateSize = () => {
  if (!smAndDown.value) {
    visibleBlocks.value = blok.links?.length ?? 3
  }
  else {
    visibleBlocks.value = 3
  }
}

const showMore = () => {
  visibleBlocks.value = blok.links?.length
}

const shouldShow = (index: number) => {
  return smAndDown.value ? index <= visibleBlocks.value : true
}

onMounted(() => {
  updateSize()
})
</script>
